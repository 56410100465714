@font-face {
  font-family: "cdx-ebony";
  src:
    url("./ebony/ebony-light.woff2") format("woff2"),
    url("./ebony/ebony-light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "cdx-ebony";
  src:
    url("./ebony/ebony-lightitalic.woff2") format("woff2"),
    url("./ebony/ebony-lightitalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "cdx-ebony";
  src:
    url("./ebony/ebony-semibold.woff2") format("woff2"),
    url("./ebony/ebony-semibold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "cdx-ebony";
  src:
    url("./ebony/ebony-semibolditalic.woff2") format("woff2"),
    url("./ebony/ebony-semibolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Golos";
  font-style: normal;
  font-display: auto;
  font-weight: 400 900;
  src: url(@fontsource-variable/golos-text/files/golos-text-latin-ext-wght-normal.woff2)
    format("woff2-variations");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308,
    U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Golos";
  font-style: normal;
  font-display: auto;
  font-weight: 400 900;
  src: url(@fontsource-variable/golos-text/files/golos-text-latin-wght-normal.woff2)
    format("woff2-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
